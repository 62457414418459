import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {trackEvent} from '../services/tracking-helper';

'use strict';

angular.module('app').controller('CheckoutShippingFormCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'AddressHelper', 'Alerts', 'blockUI', 'Hubs', 'OrderHelper', 'UserService', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, AddressHelper, Alerts, blockUI, Hubs, OrderHelper, UserService) {
  window.CheckoutShippingFormCtrl = $scope;

  $scope.checkoutCtrl = $scope.$parent.$parent.$parent.$parent.$parent;
  $scope.Hubs = Hubs;

  /**
   * Indicates that the shipping address must be used for billing data.
   * Controlled by a UI checkbox.
   *
   * @type {boolean}
   */
  $scope.sameAddressForBilling = true;

  $scope.b2bBillingAuthorizationAttempted = false;
  $scope.b2bBillingAuthorizationPassed = false;

  $scope.forceDifferentAddressForBilling = false;

  /**
   * The ship address edited in the form,
   * submitted to become the checkoutCtrl.shipAddress
   *
   * @type {object}
   */
  $scope.selectedShipAddress = undefined;

  /**
   * The bill address edited in the form
   * submitted to become the checkoutCtrl.billAddress
   *
   * @type {object}
   */
  $scope.selectedBillAddress = undefined;

  $scope.selectedBillAddressId = null;
  $scope.selectedShipAddressId = null;

  $scope.UserService = UserService;

  // Private members

  function constructor() {
    // Watch the zipcode at the selectedShipAddress, since the below watchers don't fire on select change
    $scope.$watch('selectedShipAddress', function(newValue, oldValue) {
      if ($scope.selectedShipAddress && !Hubs.zipcodeInfoCache[$scope.selectedShipAddress.zipcode])
        Hubs.getZipcodeInfo($scope.selectedShipAddress.zipcode)
    }, true);

    // Watch the zipcode to ensure that order products are deliverable to the hub
    $scope.$watch('checkoutCtrl.shipAddress.zipcode', function(newValue, oldValue) {

    });

    $scope.$watch('checkoutCtrl.shipAddress', function(newValue, oldValue) {
      if ($scope.checkoutCtrl.shipAddress != null && $scope.selectedShipAddress == null) {
        $scope.selectedShipAddress = angular.extend({}, $scope.checkoutCtrl.shipAddress);
        $timeout($scope.checkFormsValid, 100);
      }
    });

    // Setup form bill address from the checkout bill address
    $scope.$watch('checkoutCtrl.billAddress', function(newValue, oldValue) {
      if ($scope.checkoutCtrl.billAddress != null && $scope.selectedBillAddress == null) {
        $scope.selectedBillAddress = angular.extend({}, $scope.checkoutCtrl.billAddress);
        $timeout($scope.checkFormsValid, 100);
      }
    });

    $scope.$watch('checkoutCtrl.checkout.b2b_billing', function(newValue, oldValue) {
      if (newValue) {
        $scope.forceDifferentAddressForBilling = true;
        $scope.sameAddressForBilling = false;
      } else {
        $scope.forceDifferentAddressForBilling = false;
      }
    });

    // If preference address is readily available, load it immediately
    // TODO: Change to promise
    if ($scope.checkoutCtrl.preferenceShipAddresses != null) {
      if ($scope.checkoutCtrl.preferenceShipAddresses.length > 0) {
        $timeout(() => {
          if (AddressHelper.isBlank($scope.selectedShipAddress)) {
            $timeout(() => {prefillKnownAddress()}, 1)
          }
        })
      } else {
        $timeout(() => {
          if (window.Checkout_shipAddressForm)
            window.Checkout_shipAddressForm.showFullForm = true;
        }, 10)
      }
    } else { // Start watching the parent controller for preference addresses
      var unwatchCheckoutCtrlPreferenceShipAddresses = $scope.$watch('checkoutCtrl.preferenceShipAddresses', function(newValue, oldValue) {
        if (newValue != oldValue && $scope.checkoutCtrl.preferenceShipAddresses != null) {
          if ($scope.checkoutCtrl.preferenceShipAddresses.length > 0) {
            if (AddressHelper.isBlank($scope.selectedShipAddress)) {
              $timeout(() => {prefillKnownAddress()}, 1);
              unwatchCheckoutCtrlPreferenceShipAddresses()
            }
          } else {
            $timeout(() => {
              if (window.Checkout_shipAddressForm)
                window.Checkout_shipAddressForm.showFullForm = true;
            }, 10)
          }
        }
      })
    }

    // Watch 'same address' checkbox change, to ensure
    // there's a bill address reference, when this option is enabled.
    $scope.$watch('sameAddressForBilling', function(newValue) {
      if (!newValue && !$scope.selectedBillAddress) {
        // For existing customers, take the first preferred bill address
        var handleChange = function() {
          if ($scope.checkoutCtrl.preferenceBillAddresses && $scope.checkoutCtrl.preferenceBillAddresses.length > 0) {
            $scope.selectedBillAddress = angular.extend({}, $scope.checkoutCtrl.preferenceBillAddresses[0]);
            $scope.selectedBillAddress.id = null;
          } else {
            $scope.selectedBillAddress = {
              id: null,
              country_id: AddressHelper.DEFAULT_COUNTRY_ID,
              firstname: $scope.userProfile.first_name,
              lastname: $scope.userProfile.last_name,
              company: $scope.userProfile.company
            };
          }
        }

        var handleChangeIfReady = function() {
          if ($scope.userProfile == null) {
            $timeout(() => { handleChangeIfReady() }, 200);
          } else {
            handleChange();
          }
        }
        // userProfile data is required for the handler to work

        handleChangeIfReady();
      }
    });

    // $scope.$on('address:form:toggled', (event, options) => {
    //   if (!options.visible && options.isBilling && $scope.checkoutCtrl.checkout.b2b_billing) {
    //     $scope.authorizeB2bBilling();
    //   }
    // });

    if ($scope.currentHub == null) {
      Hubs.fetchCurrentHub().then(function(hub) {
        $scope.currentHub = hub
      });
    }

    handleFirstTimeCustomer();
  }

  function destructor() {
    window.CheckoutShippingFormCtrl = null;
  }

  function handleFirstTimeCustomer() {
    var firstTimeCustomerTriggered = false;

    var unwatchFirstTimeCustomer = $scope.$watch('checkoutCtrl.preferenceShipAddresses', function(newValue, oldValue) {
      if (oldValue == null && newValue != null && newValue.length == 0) {
        $scope.selectedShipAddress = {
          id: null,
          country_id: AddressHelper.DEFAULT_COUNTRY_ID,
          firstname: $scope.userProfile.first_name,
          lastname: $scope.userProfile.last_name,
          company: $scope.userProfile.company,
          gender: $scope.userProfile.gender
        };
        unwatchFirstTimeCustomer();

        setTimeout(function() {
          $scope.$digest();
        })
      }
    })
  }

  /**
   * Attempts to select the most recent known preference address
   */
  function prefillKnownAddress() {
    // Ship address
    $scope.checkoutCtrl.preferenceShipAddresses = _($scope.checkoutCtrl.preferenceShipAddresses).sortBy((address) => {
      if (address.updated_at)
        return moment(address.updated_at).unix();
      else if (address.created_at)
        return moment(address.created_at).unix();
      return 0
    }).reverse();

    if ($scope.checkoutCtrl.preferenceShipAddresses[0]) {
      $scope.selectedShipAddress = angular.extend({}, $scope.checkoutCtrl.preferenceShipAddresses[0]);

      // Force-select that address in the preference address selector dropdown:
      if ($scope.shipAddressSelector && $scope.selectedShipAddress && $scope.selectedShipAddress.id) {
        $scope.shipAddressSelector.addressId = $scope.selectedShipAddress.id.toString();
        $scope.shipAddressSelector.address = angular.extend({}, $scope.checkoutCtrl.preferenceShipAddresses[0]);

        $timeout($scope.checkFormsValid, 100);

        // Reset the ID, no longer needed for the _edited_ address:
        $scope.selectedShipAddress.id = null;
      }
    }
  }

  // Public members

  $scope.checkIfZipcodeAllowed = function(zipcode) {
    if (window.currentHubId) {
      if (!zipcode) return;
      if ($scope.lastShippingZipcode == zipcode) return;

      zipcode = $scope.lastShippingZipcode = zipcode.toString().trim();

      return $q((resolve, reject) => {
        $scope.getCurrentHub().then(hub => {
          $scope.isZipcodeInCurrentHub = hub.zipcodes.indexOf(parseInt(zipcode).toString()) > -1;

          // Check if the hub can be changed safely
          if (zipcode.length > 3 && !$scope.isZipcodeInCurrentHub) {
            Hubs.fetchHubChangeStatus(zipcode).then(function(response) {
              $scope.hubFromZipcode = response.hubFromZipcode;
              $scope.canSafelyChangeHub = response.canSafelyChangeHub;
              $scope.unavailableProducts = response.unavailableProducts;

              $scope.showZipcodeNotice = !$scope.canSafelyChangeHub
            });
          } else {
            $scope.showZipcodeNotice = false
          }
        });
      })
    } else $scope.isZipcodeInCurrentHub = false;
  };

  $scope.authorizeB2bBilling = function() {
    const params = {
      user: {email: $scope.userProfile.email},
      bill_address: angular.copy($scope.selectedBillAddress)
    };

    if (params.bill_address.country)
      delete params.bill_address.country;

    $scope.b2bBillingAuthorizationAttempted = true;
    $scope.b2bBillingAuthorizationPassed = false;
    $scope.b2bBillingAuthorizationError = null;
    $scope.b2bBillingAuthorizationLoading = true;

    return $q((resolve, reject) => {
      $http.post(`/api/checkouts/${$scope.checkoutCtrl.checkout.number}/authorize_b2b_billing.json`, params).then(response => {
        $scope.b2bBillingAuthorizationLoading = false;

        if (response.data.allowed) {
          $scope.b2bBillingAuthorizationPassed = true;
          resolve(true)
        } else {
          $scope.b2bBillingAuthorizationError = response.data.message;
          reject($scope.b2bBillingAuthorizationError)
        }
      }, e => {
        $scope.b2bBillingAuthorizationError = errorMessage(e);
        $scope.b2bBillingAuthorizationLoading = false;
        reject($scope.b2bBillingAuthorizationError)
      })
    })
  };

  $scope.checkFormsValid = function() {
    // Validate only ship address
    if ($scope.sameAddressForBilling) {
      $scope.areFormsValid = window.Checkout_shipAddressForm?.addressForm?.$valid;
    } else { // Check both shipping and billing address
      $scope.areFormsValid = window.Checkout_shipAddressForm?.addressForm?.$valid && window.Checkout_billAddressForm?.addressForm?.$valid;
    }
  };

  /**
   * Submits form with a b2b-validation logic-wrapper
   */
  $scope.submitShippingFormB2b = function() {
    $scope.authorizeB2bBilling().then(() => {
      // proceed with the normal checkout
      return $scope.submitShippingForm();
    }, (e) => {
      // b2b authorization failed, do nothing and let the user edit their inputs
    })
  };

  $scope.submitShippingForm = function() {
    return $q(function(resolve, reject) {
      blockUI.start();

      if ($scope.selectedShipAddress) {
        $scope.selectedShipAddress.errors = null;
      }

      if ($scope.selectedBillAddress) {
        $scope.selectedBillAddress.errors = null;
      }

      var checkoutParams = {
        ship_address: {
          firstname: $scope.selectedShipAddress.firstname,
          lastname: $scope.selectedShipAddress.lastname,
          gender: $scope.selectedShipAddress.gender,
          address1: $scope.selectedShipAddress.address1,
          address2: $scope.selectedShipAddress.address2,
          house_number: $scope.selectedShipAddress.house_number,
          company: $scope.selectedShipAddress.company,
          country_id: $scope.selectedShipAddress.country_id || AddressHelper.DEFAULT_COUNTRY_ID,
          zipcode: $scope.selectedShipAddress.zipcode,
          city: $scope.selectedShipAddress.city,
          phone: $scope.selectedShipAddress.phone,
          latitude: $scope.selectedShipAddress.latitude,
          longitude: $scope.selectedShipAddress.longitude,
          geocoding_provider: $scope.selectedShipAddress.geocoding_provider,
          floor: $scope.selectedShipAddress.floor,
          birth_date: AddressHelper.sanitizeBirthDate($scope.selectedShipAddress.birth_date),
          delivery_instructions: $scope.selectedShipAddress.delivery_instructions
        }
      };

      // Autofill-in the bill address from the shipping address
      if ($scope.selectedBillAddress == null || $scope.sameAddressForBilling)
        checkoutParams.bill_address = checkoutParams.ship_address;

      // TODO: Update billing address too, if needed
      if (!$scope.sameAddressForBilling) {
        // TODO: Populate bill address parameters
        checkoutParams.bill_address = {
          firstname: $scope.selectedBillAddress.firstname,
          lastname: $scope.selectedBillAddress.lastname,
          gender: $scope.selectedBillAddress.gender,
          address1: $scope.selectedBillAddress.address1,
          address2: $scope.selectedBillAddress.address2,
          house_number: $scope.selectedBillAddress.house_number,
          company: $scope.selectedBillAddress.company,
          country_id: $scope.selectedBillAddress.country_id || AddressHelper.DEFAULT_COUNTRY_ID,
          zipcode: $scope.selectedBillAddress.zipcode,
          city: $scope.selectedBillAddress.city,
          phone: $scope.selectedBillAddress.phone,
          latitude: $scope.selectedBillAddress.latitude,
          longitude: $scope.selectedBillAddress.longitude,
          geocoding_provider: $scope.selectedBillAddress.geocoding_provider,
          floor: $scope.selectedBillAddress.floor,
          birth_date: AddressHelper.sanitizeBirthDate($scope.selectedBillAddress.birth_date),
          register_number: $scope.selectedBillAddress.register_number
        }
      }

      $scope.updateCheckout(checkoutParams).then(function(checkout) {
        // Solves checkout wrongfully advancing stage if cart is empty after hub-incompatible item removal action
        if (!checkout.minimum_order_value_respected || checkout.line_items.length == 0) {
          Alerts.error(checkout.minimum_order_value_error);
          reject(checkout);
          console.log("Validations failed, redirecting back to cart");
          $timeout(() => {location.assign('/cart')}, 2000)
        }
        $scope.checkoutCtrl.updateStage().then(r => $scope.updateShippingDestinationMap().then(() => blockUI.stop())); // Force-update checkout stage on success
        // Hack alert! Change the zipcode at the header via jquery
        // until header full angularization...
        $('.header-hub-link').html($scope.selectedShipAddress.zipcode);

        if (!$scope.isZipcodeInCurrentHub) {
          Hubs.setCurrentHubFromZipcode($scope.selectedShipAddress.zipcode).then(r => {
            blockUI.stop();
            resolve(checkout)
          })
        } else {
          blockUI.stop();
          resolve(checkout)
        }
        trackEvent(
          "add_shipping_info",
          {
            products: checkout.line_items,
            checkout
          }
        );
      }, function(e) {
        var errors = new ActiveRecordErrorJar(e.data.errors);
        errors.inflateResource($scope.selectedShipAddress, "ship_address");

        if ($scope.checkoutCtrl.billAddress)
          errors.inflateResource($scope.selectedBillAddress, "bill_address");

        blockUI.stop();
        reject(e);
      });
    })
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
