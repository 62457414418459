import {trackEvent, trackPageView} from '../../services/tracking-helper';

angular.module('app').controller('Dashboard1ViewCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$window', '$element', 'Alerts', 'blockUI', 'Hubs', 'OrderHelper', '$location', '$timeout', 'UiStateService', 'SuppliersHelper', 'CatalogServiceHelper', 'CartData', 'UserService', 'SmartPassService',
  function($scope, $sce, $http, $q, $rootScope, $window, $element, Alerts, blockUI, Hubs, OrderHelper, $location, $timeout, UiStateService, SuppliersHelper, CatalogServiceHelper, CartData, UserService, SmartPassService) {
    window.Dashboard1ViewCtrl = $scope;

    //
    // Private members:
    //

    function constructor() {
      $scope.CatalogServiceHelper = CatalogServiceHelper;
      $scope.UserService = UserService;
      $scope.SmartPassService = SmartPassService;
      $scope.CartData = CartData;
      $scope.assortmentType = CartData.isExpressDelivery ? 'xpress' : 'regular';

      trackPageView('home');

      getJumboCards();

      UiStateService.noCartSidebarRequested = true;
      $rootScope.$broadcast('layout:changerequest');

      $rootScope.$on('hubs:changed', event => {
        $scope.localityDisabled = false;
        getProductsInCurrentArea();
      });

      $scope.$watch('localityMode', (newValue, oldValue) => {
        if (newValue != oldValue) getProductsInCurrentArea();
      });

      if (Hubs.currentZipcode != null) getProductsInCurrentArea();
    }

    function destructor() {
      UiStateService.noCartSidebarRequested = false;
    }

    function getJumboCards() {
      const configurationEndpoint = `/wp-api/home_banners?locale=${I18n.locale}`;

      $http.get(configurationEndpoint).then(response => {
        $scope.jumboCards = response.data;

        // Select the current language content:
        $scope.jumboCards.forEach(card => {
          if (!card) return card;
          card.link_url = card[`link_url_${I18n.locale || "de"}`];
          card.banner_image = card[`banner_image_${I18n.locale || "de"}`];
        });
      });
    }

    function getProductsInCurrentArea() {
      // in meters.
      const distance = {
        local: [25000, 5000],
        regional: [125000, 25000]
      }[$scope.localityMode] || [5000, 50];

      return new Promise((resolve, reject) => {
        let params = {
          sort_mode: 'default',
          aroundRadius: distance[0],
          per_page: 8,
          distinct: 1
        };
        if (distance[1]) params.aroundPrecision = distance[1];

        CatalogServiceHelper.loadProducts(params).then(products => {
          $scope.localProductList = products;
          resolve($scope.localProductList);
        });
      });
    }

    if (Rails.env === 'development') $scope.getProductsInCurrentArea = getProductsInCurrentArea;

    $scope.trackPromotionEvent = function(card, position) {
      const gtmObject = {name: card.title, id: card.promo_id, position};
      trackEvent('promotion_click', {gtmObject});
    };

    //
    // Public members
    //

    $scope.localityMode = 'nearby';

    $scope.onJumboBackdropLoad = function() {
      $scope.jumboBackdropLoaded = true;
    };

    $scope.onForegroundImageLoad = function() {
      $scope.fullImageLoaded = true;
    };

    $scope.discountedProductsSelectionUrl = "?" + encodeURIComponent('s[certificate_ids][]') + '=' + window.underDiscountCertificateId;

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
