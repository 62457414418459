import * as _ from 'underscore';
import {trackPageView, trackEvent} from '../services/tracking-helper';

'use strict';

angular.module('app').controller('CheckoutPaymentStageCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'Alerts', 'blockUI', 'CartData', 'UserNotifications', '$translate',
  function($scope, $sce, $http, $q, $rootScope, $timeout, $element, Alerts, blockUI, CartData, UserNotifications, $translate) {
    window.CheckoutPaymentStageCtrl = $scope;

    // Public properties

    $scope.checkoutCtrl = $scope.$parent.$parent;

    $scope.couponCodes = [];
    $scope.currentCouponCodeIndex = -1;

    //
    // Private members
    //

    function constructor() {
      $scope.$watch('checkoutCtrl.stage', function(newValue) {
        if ($scope.checkoutCtrl.checkout && !$scope.checkoutCtrl.isCheckoutStageBefore('payment')) {
          $rootScope.$broadcast('paymentStage:loaded');

          if ($scope.checkoutCtrl.availablePaymentMethods == null) {
            $scope.checkoutCtrl.getPaymentMethods();
          }
          trackPageView('checkout', {products: $scope.checkoutCtrl.checkout?.line_items, checkout: $scope.checkoutCtrl.checkout});
        }
      });
      $scope.$watch('checkoutCtrl.checkout', function() {
        if ($scope.checkoutCtrl.checkout) {
          $scope.parseCouponCodes();
        }
      });
    }

    function destructor() {
      window.CheckoutPaymentStageCtrl = null;
    }

    //
    // Public methods
    //

    $scope.selectPaymentMethod = function(paymentMethod) {
      if ($scope.selectedPaymentMethod === paymentMethod) {
        $scope.selectedPaymentMethod = null;
      } else {
        if (paymentMethod.type === 'Spree::Gateway::InvoiceGateway') {
          const overdueDate = $scope.order.invoice_overdue_date;

          if ($scope.order.invoice_max_limit_reached) {
            Alerts.error($translate.instant('checkout.invoice_limit'));
            return;
          }

          if ($scope.order.invoice_overdue_date) {
            Alerts.error($translate.instant('checkout.invoice_overdue', {date: overdueDate}));
            return;
          }
        }

        $scope.selectedPaymentMethod = paymentMethod;
        if (window.hj && paymentMethod) {
          try {
            window.hj('vpv', `/checkout/${$scope.checkoutCtrl.stage}/${paymentMethod.type.split('::')[2]}`);
          } catch (e) {
            // if (window.airbrake) window.airbrake.notify(e);
            console.error(e);
          }
        }
      }
    };

    /**
     * Gets an existing eligible adjustment for a Guthaben discount, if it exists
     */
    $scope.getAccountBalanceDiscountAdjustments = function() {
      _.select($scope.checkoutCtrl.adjustments, a => a.source_type === 'Spree::UserAccountTransaction' && a.eligible);
    };

    /**
     * Gets a sum of all existing eligible adjustment for a Guthaben discount, if it exists
     */
    $scope.getAccountBalanceDiscountAdjustmentSum = function() {
      _.chain($scope.checkoutCtrl.adjustments).select(a => a.source_type === 'Spree::UserAccountTransaction' && a.eligible).map(a => a.amount).inject((memo, num) => memo + num, 0);
    };

    $scope.submitPayment = function() {
      // TODO
    };

    $scope.parseCouponCodes = function() {
      const checkout = $scope.checkoutCtrl.checkout;

      if (checkout?.promo_handles?.promotion_actions_applied) {
        Object.values(checkout.promo_handles.promotion_actions_applied).forEach(promo => {
          [promo].flat().forEach(pr => {
            $scope.couponCodes.push({code: pr.code.toUpperCase(), applied: true, messages: null, submitted: true, valid: true});
          });
        });
      }
      if (checkout?.can_add_another_voucher) {
        $scope.currentCouponCodeIndex = $scope.couponCodes.length;
        $scope.couponCodes.push({code: "", applied: false, messages: null, submitted: false, valid: false});
      }
    };

    $scope.submitCouponCode = function() {
      blockUI.start();
      const couponCode = $scope.couponCodes[$scope.currentCouponCodeIndex].code;

      return $q((resolve, reject) => {
        $scope.checkoutCtrl
          .updateCheckout({coupon_code: couponCode}, true)
          .then(c => {
            $scope.couponCodes[$scope.currentCouponCodeIndex].valid = true;
            $scope.couponCodes[$scope.currentCouponCodeIndex].submitted = true;
            $scope.couponCodes[$scope.currentCouponCodeIndex].applied = true;
            trackEvent("coupon_code_applied",
              {
                coupon: c.coupon_code,
                coupon_message: c?.coupon_messages[0],
                products: c.line_items,
                checkout: c
              });
            if (c?.coupon_messages) {
              $scope.couponCodes[$scope.currentCouponCodeIndex].messages = c.coupon_messages.join(" ");
            }
            if (c?.can_add_another_voucher) {
              $scope.currentCouponCodeIndex = $scope.couponCodes.length;
              $scope.couponCodes.push({code: "", applied: false, messages: null, submitted: false, valid: false});
            }
            resolve(c);
            blockUI.stop();
            CartData.load();
            if (window.CheckoutCtrl) {
              window.CheckoutCtrl.updateCheckout(null, true);
            }
            $scope.checkoutCtrl.getPaymentMethods(); // Guthaben might now have some new funds
          }, function(e) {
            const errorMessage = e.data.errors.coupon_code[0];
            $scope.couponCodes[$scope.currentCouponCodeIndex].valid = false;
            $scope.couponCodes[$scope.currentCouponCodeIndex].submitted = true;
            $scope.couponCodes[$scope.currentCouponCodeIndex].messages = errorMessage;
            trackEvent("coupon_code_rejected",
              {
                coupon: couponCode,
                coupon_message: errorMessage,
                products: $scope.checkoutCtrl.checkout?.line_items,
                checkout: $scope.checkoutCtrl.checkout
              });
            blockUI.stop();
          });
      });
    };

    $scope.submitOrderWithZeroTotal = function() {
      $scope.formError = null;

      blockUI.start();

      const params = {
        id: $scope.checkoutCtrl.checkout.id,
        number: $scope.checkoutCtrl.checkout.number
      };

      return $q(function(resolve, reject) {
        $scope.checkoutCtrl.updateCheckout(params).then((r) => {
          resolve(r);
          blockUI.stop();

          if (r.state === 'complete') console.log('Checkout was completed');
        }, e => {
          Alerts.error(errorMessage(e));
          blockUI.stop();
        });
      });
    };

    $scope.handleCurrentCouponCodeChange = function() {
      $scope.couponCodes[$scope.currentCouponCodeIndex].submitted = null;
      $scope.couponCodes[$scope.currentCouponCodeIndex].messages = null;
    };

    // Initialize

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
