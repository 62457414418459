import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyPreferredAddressSelector', function() {
    return {
      controller: ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'Alerts', 'blockUI', 'AddressHelper', 'UserService', '$uibModal', function ($scope, $sce, $http, $q, $rootScope, $timeout, Alerts, blockUI, AddressHelper, UserService, $uibModal) {
        window.PreferredAddressSelectorCtrl = $scope;

        function constructor() {
          if ($scope.addressfilter == null)
            $scope.addressfilter = {};

          var intializationWatcher = $scope.$watch('addresses', (newValue, oldValue) => {
            if ($scope.addresses && $scope.address) {
              if (!AddressHelper.contains($scope.addresses, $scope.address)) {
                $scope.addresses.push(angular.extend({}, $scope.address) /* we need a clone here */);
              }

              // Find the correct address to show in the selector upon start
              if ($scope.address.id) {
                $scope.addressId = $scope.address.id.toString();
              } else {
                var optionAddress = _.find($scope.addresses, (a) => AddressHelper.isEqual($scope.address, a));

                if (optionAddress && optionAddress.id){
                  $scope.addressId = optionAddress.id.toString();
                }
              }

              onChangeCallback();

              intializationWatcher();
            } else if ($scope.addresses) {
              $scope.addressId = $scope.addresses[0].id.toString();
            }
          })
        }

        function destructor() {
          window.PreferredAddressSelectorCtrl = null;
        }

        // Public members

        $scope.addressChanged = () => {
          // It's important to _extend_ the existing reference here, otherwise the binding
          // with the parent controller won't work
          $scope.address = angular.extend($scope.address || {}, _.find($scope.addresses, (a) => a.id.toString() == $scope.addressId.toString()));

          if ($scope.address && $scope.address.address1) {
            $scope.address.isNew = false;
            $scope.isNewAddress = false;

            if (!$scope.address.register_number && $scope.isBilling && UserService.currentUser) {
              $scope.address.register_number = UserService.currentUser.corporate_register_number
            }
          }

          $scope.address.id = null; // reset the id

          onChangeCallback();
        };

        $scope.setNewAddress = () => {
          $scope.isNewAddress = true;
          $scope.addressId = "";

          $scope.address = angular.extend($scope.address || {}, {
            id: null,
            isNew: true,
            firstname: "",
            lastname: "",
            address1: "",
            house_number: "",
            gender: "",
            zipcode: "",
            city: "",
            company: "",
            phone: "",
            birth_date: null,
            delivery_instructions: ""
          });

          if ($scope.notifyOnNew) {
            if ($scope.notifyOnNew.$broadcast) {
              $scope.notifyOnNew.$broadcast("addressselector:new");
            } else {
              throw new Error("Cannot $broadcast to notifyOnNew - no interface.");
            }
          }

          onChangeCallback()
        };

        // Removes the current address as "preferred" (doesn't erase it from DB!).
        $scope.removeCurrentAddress = function() {
          if ($scope.address || $scope.addressId) {
            // Launch confirmation modal

            let removeConfirmation = $uibModal.open(
              {
                animation: true,
                size: 'md',
                templateUrl: '/ng/templates/addresses/remove_confirmation_modal.html',
                windowClass: 'address-confirmation-modal modal-rounded',
                controller: 'ConfirmationModalCtrl',
                resolve: {
                  address: $scope.address
                }
              }
            );

            removeConfirmation.result.then((result) => {
              if (result.confirmed) {
                let addressId = ($scope.address && $scope.address.id) || $scope.addressId;
                blockUI.start();

                $http.post(`/api/frontend/addresses/${addressId}/remove_preferred.json`).then((response) => {
                  blockUI.stop();
                  $scope.addresses = _($scope.addresses).reject((address) => address.id == $scope.addressId);
                  if ($scope.addresses[0]) {
                    $scope.addressId = $scope.addresses[0].id.toString();
                    $scope.addressChanged();
                  } else
                    $scope.setNewAddress();
                }).finally(() => { blockUI.stop() })
              }
            });
          }
        };

        // Private members

        // Call "onChange()" only if defined as a function.
        // Important: this callback passes the selected address as an argument.
        function onChangeCallback() {
          if ($scope.hasCallbackFunction)
            $scope.onChange()($scope.address)
        }

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        address: '=',
        addresses: '=',
        addressId: '=?bind',
        isBilling: '=',
        notifyOnNew: '=',
        onChange: '&'
      },
      link: function($scope, $element, attributes) {
        // Since "ngChange" is inside the declared scope, it will always return a function.
        // This way we confirm that a callback function actually lives inside.
        if (attributes.onChange)
          $scope.hasCallbackFunction = true;

        if (attributes.name) {
          $scope.$parent[attributes.name] = $scope;

          // A hack to fix https://farmyag.atlassian.net/browse/IM-1464
          // there's a problem with exposing a named reference in the parent CheckoutShippingFormCtrl.
          // Could not find any apropriate angular way to fix it, it supposedly 'breaks the controller pattern'...
          if ($scope.$parent.$parent) $scope.$parent.$parent[attributes.name] = $scope;
        }
      },
      templateUrl: function() { return `/ng/templates/checkout/preferred_address_selector.html?locale=${window.I18n.locale}` }
    }
  });

// Confirmation modal controller
angular.module('app')
  .controller('ConfirmationModalCtrl', ['$scope', '$uibModalInstance', 'address', function($scope, $uibModalInstance, address) {
    console.log('AddressRemoval is here!');
    $scope.address = address;

    $scope.onConfirmClicked = function() {
      $uibModalInstance.close({confirmed: true})
    };

    $scope.onDismissClicked = function() {
      $uibModalInstance.close({confirmed: false})
    };
  }]);


